// ** React Imports
import { Link, useHistory } from "react-router-dom"

// ** Reactstrap Imports
import { CardTitle, CardText, Button } from "reactstrap"
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { Buffer } from "buffer";

// ** Styles
import './styles.css';

import { useState } from "react";
import axios from "axios";
import {
  LoginSocialFacebook,
} from 'reactjs-social-login';
import ComponentSpinner from "../components/ComponentSpinner";
import LogoHeadr from "../components/LogoHeadr";

const SocialLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: process.env.REACT_APP_LINKEDIND_APP_ID,
  //   scope: "r_liteprofile r_emailaddress",
  //   redirectUri: `${window.location.origin}/LeaveMessageln`,
  //   onSuccess: (code) => {
  //     alert(code);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  const linkedInPress=async()=>{
    const clientId =  process.env.REACT_APP_LINKEDIND_APP_ID;
    const redirectUri = `${window.location.origin}/LeaveMessageln`;
    const scope = "r_liteprofile r_emailaddress";

    const stateKey = localStorage.getItem('url_id')
    
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${stateKey}&scope=${scope}`;
    
    window.location.href = linkedInAuthUrl;
  }

  const responseFacebook = async (data) => {
    if (data.accessToken) {
      setLoading(true)
      try {
        const _response = await axios.get(data.picture.data.url, {
          responseType: "arraybuffer"
        });
        const buffer = Buffer.from(_response.data, "binary").toString("base64");
        const imageBase64 = `data:${_response.headers["content-type"]};base64,${buffer}`
        localStorage.setItem('name', JSON.stringify(data.name))
        const email = data?.email || ""
        localStorage.setItem('email', email)
        localStorage.setItem('imagebase64', JSON.stringify(imageBase64))
        localStorage.setItem('socialType', JSON.stringify("facebook"))
        setLoading(false)
        history.push('/LeaveMessagefb')
      } catch (error) {
        alert(error)
        console.log('errrrr==>>>>', error)
      }
    } else {
      setLoading(false)
    }
  }

  if (loading) return <ComponentSpinner />

  return (
    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <LogoHeadr />
        <div className="title">
          Get verified
        </div>
        <div className="grey_regular_16 mb-6" style={{ color: 'black', fontFamily: 18 }}>
          We need to make sure you are the right person. Click one of the
          button below to verify your LinkedIn or Facebook account.
        </div>
        <button className="btn"
          onClick={() => linkedInPress()}>
          <span className="title" style={{ fontSize: 16, color: 'white' }}>Verify LinkedIn</span>
        </button>

        <LoginSocialFacebook
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="name,email,picture"
          scope="email,public_profile"
          redirect_uri={`${window.location.origin}/social-login`}
          onResolve={({ provider, data }) => {
            console.log("provider====>",provider)
            console.log("data====>",data)
            responseFacebook(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <button className="btn">
            <span className="title" style={{ fontSize: 16, color: 'white' }}>Verify Facebook</span>
          </button>
        </LoginSocialFacebook>

      </div>
    </div>
  )
}

export default SocialLogin;
