import React, { useLayoutEffect, useMemo, useState } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, getDay, parse, setMinutes, setHours, subDays, startOfDay, differenceInMinutes } from 'date-fns';
import { Button, Spinner } from 'react-bootstrap';
import Select from 'react-select'

import { useHistory } from 'react-router-dom'
import { storage } from './firebase'
import { Buffer } from "buffer";
import axios from "axios";
import { MobileTimePicker, TimePicker } from '@mui/x-date-pickers';

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const timezones = {
  PT: "America/Los_Angeles",
  MT: "America/Denver",
  CT: "America/Chicago",
  ET: "America/New_York",
};

const options = [
  { value: 'PT', label: 'PT' },
  { value: 'CT', label: 'CT' },
  { value: 'MT', label: 'MT' },
  { value: 'ET', label: 'ET' },
]

const durationOptions = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '60', label: '60' },
]

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Ensure it's above other elements
  }),
};


const Scheduler = ({scheduleArray}) => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false);
  const [timeZone, setTimeZone] = useState("PT");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDay, setSelectedDay] = useState({ name: 'Sunday', status: false, day: 0, start_time: '08:00', end_time: '18:00' });
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null); // Set current time
  const [duration, setDuration] = useState(null); // Set current time
  const [email, setEmail] = useState(localStorage.getItem('email')|| ""); // New state for emai

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      margin: '0 auto',
      maxWidth: '600px',
      backgroundColor: '#fff',
    },
    calendar: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: '#9C5EFF',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '14px'
    },
    icon: {
      cursor: 'pointer',
      userSelect: 'none'
    },
    days: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '10px'
    },
    row: {
      display: 'flex',
      fontSize: '10px'
    },
    col: {
      flex: '1 1 14.28%', // Ensures seven columns fit within 100%
      padding: '10px',
      textAlign: 'center',
      borderRadius: '4px',
      margin: '2px',
      backgroundColor: '#f1f1f1',
      cursor: 'pointer',
      boxSizing: 'border-box'
    },
    cell: {
      cursor: 'pointer'
    },
    selected: {
      backgroundColor: '#03a9f4',
      color: 'white'
    },
    currentDay: {
      border: '2px solid #ff5722'
    },
    disabled: {
      color: '#ccc',
      cursor: 'not-allowed' // Change cursor for disabled days
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold'
    },
    input: {
      width: '97%',
      padding: '8px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd'
    },
    emailinput:{
      width: '97%',
      padding: '8px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '14px'
    },
    button: {
      backgroundColor: '#03a9f4',
      color: '#fff',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '100%'
    },
    modal: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        width: '400px',
        textAlign: 'center'
      }
    },
 
  };

  const renderHeader = () => {
    const today = new Date();
    const weekStart = startOfWeek(today, { weekStartsOn: 0 }); // Sunday as start of the week
    const weekEnd = endOfWeek(today, { weekStartsOn: 0 }); // Saturday as end of the week
    const isPrevDisabled = startOfDay(calenderDate) <= startOfDay(weekStart);
    const isNextDisabled = startOfDay(calenderDate) >= startOfDay(weekEnd);
    
    return (
      <div style={styles.header}>
        <div
          style={{
            ...styles.icon,
            ...(isPrevDisabled ? { color: 'gray', cursor: 'not-allowed' } : {}),
          }}
          onClick={!isPrevDisabled ? () => setCalenderDate(subDays(calenderDate, 1)) : null}
        >
          &lt; Prev
        </div>
        <div>
         <span>{`${format(weekStart, 'MMMM yyyy')}`}</span>
        </div>
        <div
          style={{
            ...styles.icon,
            ...(isNextDisabled ? { color: 'gray', cursor: 'not-allowed' } : {}),
          }}
          onClick={!isNextDisabled ? () => setCalenderDate(addDays(calenderDate, 1)) : null}
        >
          Next &gt;
        </div>
      </div>
    );
  };
  
  const renderDays = () => {
    const days = [];
    const dateFormat = "eee"; // Format for short weekday names
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 }); // Sunday as start of the week
  
    // Generate days for the current week
    for (let i = 0; i < 7; i++) {
      const currentDay = addDays(weekStart, i);
      days.push(
        <div style={styles.col} key={i}>
          {format(currentDay, dateFormat)}
        </div>
      );
    }
  
    return <div style={styles.days}>{days}</div>;
  };
  
  const renderCells = () => {
    const today = new Date();
    const weekStart = startOfWeek(today, { weekStartsOn: 0 }); // Sunday as start of the week
    const weekEnd = endOfWeek(today, { weekStartsOn: 0 }); // Saturday as end of the week
  
  const rows = [];
  let days = [];
  let day = weekStart;

  while (day <= weekEnd) {
    for (let i = 0; i < 7 && day <= weekEnd; i++) {
      const formattedDate = format(day, "d");
      const cloneDay = day;

      const dayData = scheduleArray.find((item) => item.day === getDay(day));
      const isDisabled =
        !dayData?.status || startOfDay(day) < startOfDay(today); // Disable previous days
      const isCurrentDay = isSameDay(day, today);
      const isSelected = selectedDate && isSameDay(day, selectedDate);

      days.push(
        <div
          style={{
            ...styles.col,
            ...styles.cell,
            ...(isDisabled ? styles.disabled : {}),
            ...(isCurrentDay ? styles.currentDay : {}),
            ...(isSelected ? styles.selected : {}),
          }}
          key={day}
          onClick={() => {
            if (!isDisabled) {
              setCalenderDate(cloneDay);
              setSelectedDate(cloneDay);
              setSelectedDay(dayData);
              setSelectedTime(null);
            }
          }}
        >
          <span>{formattedDate}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div style={styles.row} key={day}>
        {days}
      </div>
    );
    days = [];
  }
    return <div>{rows}</div>;
  };

  const minTime = useMemo(() => {
    const [hours, minutes] = selectedDay.start_time.split(":").map(Number);
    
    const tmzone=localStorage.getItem("timezone")

    // Convert the availability time to the specified timezone
    const availabilityTime = dayjs()
      .tz(timezones[tmzone])
      .set("hour", hours)
      .set("minute", minutes);
  
    // Convert availability time to user's selected timezone
    const userTimezone = timezones[timeZone]|| timezones['PT']; // Ensure selectedTimezone is 'PT', 'CT', etc.
    const userMinTime = availabilityTime.tz(userTimezone);
  
    return userMinTime;
  }, [selectedDay, timeZone]);


const maxTime = useMemo(() => {
  const [maxHours, maxMinutes] = selectedDay.end_time.split(":").map(Number);

  const tmzone=localStorage.getItem("timezone")
  // Convert the end time to the availability timezone
  const availabilityEndTime = dayjs()
    .tz(timezones[tmzone])
    .set('hour', maxHours)
    .set('minute', maxMinutes);

  // Adjust max time by subtracting 10 minutes
  const adjustedAvailabilityEndTime = availabilityEndTime.subtract(10, 'minute');

  // Convert the adjusted max time to user's selected timezone
  const userTimezone = timezones[timeZone] || timezones['PT']; // Ensure selectedTimezone is 'PT', 'CT', etc.
  const userMaxTime = adjustedAvailabilityEndTime.tz(userTimezone);

  return userMaxTime;
}, [selectedDay, timeZone]);

  const meetingDurationOptions=useMemo(()=>{
    setDuration(null)
    // Parse the end_time and selected_time to valid Date objects
    const endDateTime = parse(selectedDay.end_time, 'HH:mm', new Date());
    const selectedDateTime =selectedTime;
  
    // Calculate the remaining time in minutes between selected and end time
    const remainingMinutes = differenceInMinutes(endDateTime, selectedDateTime);
    // Filter options based on the remaining time
    const filteredOptions = durationOptions.filter((option) => {
      const optionValue = parseInt(option.value, 10);
      return remainingMinutes >= optionValue;
    });
  
    return filteredOptions;
  
  },[selectedTime,selectedDay])


  const handleSubmit = async() => {
    const currentTime = dayjs(selectedTime).format("hh:mm a").toUpperCase(); // Get current time
    const formattedDate = format(selectedDate, "EEE MMM dd yyyy");
    const time=formattedDate+" "+currentTime+" "+timeZone
    if (duration==null) return;
    setIsLoading(true)
    const uid = localStorage.getItem('url_id')
    const name = JSON.parse(localStorage.getItem('name'))
    const socialType = JSON.parse(localStorage.getItem('socialType'))
    const imagebase64 = JSON.parse(localStorage.getItem('imagebase64'))
    const message = localStorage.getItem('messageContent')
    try {
      let url=''
      if (imagebase64) {
        const file = imagebase64.split(";base64,").pop();
        const buffer = Buffer.from(file, "base64");
        const blob = new Blob([buffer], { type: "image/jpeg" });
  
        const imageFilleName = `testimonials/${uid}_${new Date().toISOString().replace(/[.:-]+/g, "_")}`;
  
        const storageRef = storage.ref(imageFilleName);
        await storageRef.put(blob);
        url = await storageRef.getDownloadURL();
      }

      let data = JSON.stringify({
          uid: uid,
          name: name,
          imagebase64: url,
          socialType: socialType,
          messages: message,
          email: email,
          date:time,
          duration:duration.value
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://us-central1-beyouid200.cloudfunctions.net/calendarBooking',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        setIsLoading(false);
        history.push('/Thanks');
      })
      .catch((error) => {
        console.log(error);
        alert("Error: " + error.response.data.message)
        setIsLoading(false)
      });
    } catch (error) {
      console.log('11err=>>', error)
      alert("Internet error. Try again later")
      setIsLoading(false)
    }
  };

  return (
    <div style={styles.container}>
      <div className="title mb-3 text-purple">Book your appointments</div>
      <div>
      <div className="grey_regular_16" style={{ color: 'black', fontFamily: 18 }}> Email</div>
      <input
          style={styles.emailinput}
          type="email"
          value={email}
          defaultValue={localStorage.getItem('email')|| ""}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div style={styles.calendar}>
        {renderHeader()}
        {renderDays()}
        {renderCells()}
      </div>
      <div className='mt-6'>
        <label style={styles.label}>TimeZones</label>
        <Select 
         styles={customStyles}
          defaultValue={options[0]} 
          options={options} 
          menuPortalTarget={document.body}
          onChange={(v)=>{
            if (v.label) {
              setDuration(null)
              setTimeZone(v.label)
          }}} />
      </div>

      {selectedDate&&
      <div className='mt-6'>
        <MobileTimePicker 
          ampm={false}
          ampmInClock={true}
          label="Time Slots"
          value={selectedTime}
          minTime={minTime}
          maxTime={maxTime}
          onChange={(newValue) => {
            setSelectedTime(newValue)}}
         />
      </div>
      }
      {
        selectedTime &&
       <div className='mt-6'>
        <label style={styles.label}>Meeting Duration</label>
        <Select 
          styles={customStyles}
          value={duration}
          placeholder='Meeting Duration'
          options={meetingDurationOptions} 
          menuPortalTarget={document.body}
          onChange={(v)=>v.label && setDuration(v)} />
      </div>
      }

        <Button disabled={email==""||selectedDate == null || duration==null || selectedTime==null || isLoading} onClick={handleSubmit} className="btn w-[100%]  d-flex align-items-center justify-content-center gap-2 primary-btn00 rounded-[10px] h-[3rem] primary-bg medium-font mt-4 text-[1rem]">
          {isLoading ? <Spinner size="sm" /> :"Request an appointment"}
        </Button>
    </div>
  );
};

export default Scheduler;